<template>
    <div class="l_form">
        <p class="guide_text">은 필수값입니다.</p>
        <div class="required">
            <label>업체명(법인명, 상호명, 성함)</label>
            <div class="input_wrapper">
                <input v-model="name" type="text" class="c_input_basic" placeholder="업체명을 입력하세요." />
            </div>
        </div>
        <div class="homepage">
            <label>홈페이지 <span class="sub_text">(선택)</span></label>
            <div class="input_wrapper">
                <input v-model="url" type="text" class="c_input_basic" placeholder="http:// 주소를 입력해 주세요." />
            </div>
        </div>
        <div class="required">
            <label>휴대폰 번호</label>
            <div class="input_wrapper">
                <input v-model="contact" type="text" maxlength="13" class="c_input_basic" placeholder="휴대폰 번호를 -없이 입력하세요." />
            </div>
        </div>
        <div>
            <label>사업형태</label>
            <div class="input_wrapper">
                <select v-model="division" class="c_input_basic">
                    <option value="">사업형태를 선택하세요.</option>
                    <option value="법인">법인</option>
                    <option value="개인">개인</option>
                </select>
            </div>
        </div>
        <div>
            <label>예상 유치 가맹점 수</label>
            <div class="input_wrapper">
                <input v-model="franchisee" type="number" class="c_input_basic" placeholder="예상 유치 가맹점 수를 입력하세요." />
            </div>
        </div>
        <div>
            <label>이메일(ID)</label>
            <div class="input_wrapper">
                <input v-model="email" type="email" class="c_input_basic" placeholder="이메일 주소를 입력하세요." />
            </div>
        </div>
        <div>
            <label>문의사항</label>
            <div class="input_wrapper">
                <input v-model="content" type="text" class="c_input_basic" placeholder="문의사항을 입력하세요." />
            </div>
        </div>
        <div class="terms">
            <label>개인정보취급방침</label>
            <div class="input_wrapper">
                <div style="height:0;">
                    <label>
                        <span>&nbsp;</span>
                    </label>
                </div>
                <div>
                    <div class="termBox" id="privacyPolicy" v-html="privacyPolicy"></div>
                    <label>
                        <input v-model="agree_yn" type="checkbox" />
                        <span>개인정보취급방침에 동의합니다.</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="l_btn_wrapper_bottom">
        <button @click="register" class="c_btn_common c_btn_primary-fill">가입신청</button>
    </div>
</template>

<script>
import { fetchDataEl } from '@/utils/fetchDataEl';
import { postData } from '@/utils/postData';
import { phoneFormat } from '@/utils/formatting/phoneFormat';

export default {
    name : 'ReSeller',
    mixins : [ fetchDataEl, postData, phoneFormat ],
    watch : {
        contact(newVal){
            this.contact = this.phoneFormat(newVal);
        },
        franchisee(newVal){
            newVal = newVal<0 ? 0 : newVal;
            newVal = String(newVal).replace(/[^0-9]/g, "").replace(/(^0+)/, "")*1;
            this.franchisee = newVal;
        }
    },
    data(){
        return{
            // 입력값
            name : '',
            url : '',
            email : '',
            contact : '',
            division : '',
            content : '',
            franchisee : '',
            agree_yn : false,

            // 약관
            privacyPolicy : '',
        }
    },
    mounted() {
        this.getTerms();
    },
    methods:{
        // 약관 fetch
        getTerms(){
            const privacyPolicyEl = document.getElementById('privacyPolicy');
            this.fetchDataEl('/getPrivacypolicy', (data)=>{
                this.privacyPolicy = data.content;
            }, privacyPolicyEl);
        },
        // 가입신청
        register(){
            if(!this.name || this.contact.length<13){
                this.$store.commit('error', '필수정보를 입력해주세요')
            } else if(!this.agree_yn){
                this.$store.commit('error', '개인정보취급방침에 동의해주세요')
            } else {
                this.postData('/saveReseller', {
                    name : this.name,
                    url : this.url,
                    email : this.email,
                    contact : this.contact,
                    division : this.division,
                    content : this.content,
                    franchisee : this.franchisee,
                    agree_yn : this.agree_yn,
                }, ()=>{
                    this.$store.commit('alert', '가입이 완료되었습니다.')
                    this.name = '';
                    this.url = '';
                    this.email = '';
                    this.contact = '';
                    this.division = '';
                    this.content = '';
                    this.franchisee = '';
                    this.agree_yn = false;
                })
            }
        }
    }

}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_customer.css"></style>

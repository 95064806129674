<template>
    <div class="customer_faq">
        <ul class="l_tab_mid02">
            <li class="on" @click="order($event)">전체</li>
            <li @click="order($event)">가입</li>
            <li @click="order($event)">결제</li>
            <li @click="order($event)">수수료</li>
            <li @click="order($event)">정산</li>
            <li @click="order($event)">취소</li>
            <li @click="order($event)">기타</li>
        </ul>
        <!-- 공지사항 리스트 START -->
        <ul class="l_board_list">
            <li v-for="item in this.orderedList" :key="item.faq_pk">
                <div @click="openQuestion($event, item.faq_pk)"
                    class="question"
                >
                    <p>{{item.division}}</p>
                    <p class="title">{{item.inquiry}}</p>
                </div>
                <div class="answer">
                    <p>답변</p>
                    <p v-if="!item.answer">답변이 없습니다.</p>
                    <p v-else>{{item.answer}}</p>
                </div>
            </li>
            <div v-if="this.orderedList.length == 0" class="c_default_text">자주 묻는 질문이 없습니다.</div>
        </ul>
        <!-- 공지사항 리스트 END -->
        <Pagination
            :page="page"
            :list="list"
            :maxShowPage="maxShowPage"
            :maxShowList="maxShowList"
        />
    </div>
</template>

<script>
import Pagination from '@/components/customer/Pagination';

export default {
    name : 'Faq',
    components : {
        Pagination,
    },
    props : {
        page : String,
    },
    computed : {
        orderedList(){
            if(this.which == '전체'){
                return this.list;
            }
            return this.list.filter(el=>el.division == this.which);
        }
    },
    data(){
        return {
            list : [],
            maxShowPage : 10,   // 한 화면에 보일 최대 페이지수
            maxShowList : 10,   // 한 페이지에 보일 최대 리스트 수

            // sort를 위한
            which : '전체',
        }
    },
    created(){
        // 진입 시 페이지 page 쿼리 없거나 page<=0이면 page=1 쿼리 페이지로 교체 (최대는 fetchBoardData안에 설정해놓음)
        if(!this.page || this.page <= 0){
            this.$router.replace({
                path : this.$route.path,
                query : {
                    page : 1,
                }
            })
        }
        this.fetchFaqData();
    },
    methods:{
        fetchFaqData(){
            this.fetchData('/getFaq', (res)=>{
                this.list = res.data;
            })
        },
        order(event){
            const onEl = document.querySelectorAll('.l_tab_mid02 li');
            for(let i=0;i<onEl.length;i++){
                onEl[i].classList.remove('on');
            }
            event.currentTarget.classList.add('on')
            this.which = event.currentTarget.innerHTML;
        },
        openQuestion(event, pk){
            if(!event.currentTarget.classList.contains('on')){
                // 조회수 올리기
                this.axios.post('/viewFaq', {
                    pk : pk,
                })
            }
            const onEl = document.querySelectorAll('.question.on');
            event.currentTarget.classList.toggle('on')
            for(let i=0;i<onEl.length;i++){
                if(onEl[i] != event.currentTarget){
                    onEl[i].classList.remove('on');
                }
            }
        },
    }

}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_customer.css"></style>
